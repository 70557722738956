.block-services {

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        margin-bottom: 1rem;
        background: #fff;
        padding: 1.5rem 1rem;
        transition: linear .25s;

        &:hover {
            box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
        }

        &_image {
            margin-bottom: .5rem;
        }
        &_name {
            font-size: 1.25rem;
            margin-bottom: .5rem;
        }

        &_description {
            text-align: center;
            margin-bottom: 1rem;
        }

        &_spacer {
            flex-grow: 1;
        }

        &_all {
            
        }


    }
}
/*
// .site
*/
@import '../variables';


.site {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
.site__header {
    flex-shrink: 0;
}
.site__body {
    flex-grow: 1;

    // &::before {
    //     content: "";
    //     position: fixed;
    //     top: 0;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    //     background-image: url(../images/bg.jpg);
    //     opacity: .1;
    //     z-index: -2;
    // }
}
.site__footer {
    flex-shrink: 0;
}
/*
// .filter-price
*/
@import '../variables';


.filter-price {}
.filter-price__slider {
    margin-top: 6px;
}
.filter-price__title {
    margin-top: 16px;
    font-size: 14px;
    color: $card-font-muted-color;
}

/*
// стили для ms2 slider
*/

// .ui-helper-hidden {
//     display: none;
// }
// .ui-helper-hidden-accessible {
//     border: 0;
//     clip: rect(0 0 0 0);
//     height: 1px;
//     margin: -1px;
//     overflow: hidden;
//     padding: 0;
//     position: absolute;
//     width: 1px;
// }
// .ui-helper-reset {
//     margin: 0;
//     padding: 0;
//     border: 0;
//     outline: 0;
//     line-height: 1.3;
//     text-decoration: none;
//     font-size: 100%;
//     list-style: none;
// }
// .ui-helper-clearfix:before,
// .ui-helper-clearfix:after {
//     content: "";
//     display: table;
//     border-collapse: collapse;
// }
// .ui-helper-clearfix:after {
//     clear: both;
// }
// .ui-helper-zfix {
//     width: 100%;
//     height: 100%;
//     top: 0;
//     left: 0;
//     position: absolute;
//     opacity: 0;
//     filter: Alpha(Opacity=0);
// }
// .ui-front {
//     z-index: 100;
// }
// .ui-state-disabled {
//     cursor: default !important;
//     pointer-events: none;
// }
// .ui-icon {
//     display: inline-block;
//     vertical-align: middle;
//     margin-top: -0.25em;
//     position: relative;
//     text-indent: -99999px;
//     overflow: hidden;
//     background-repeat: no-repeat;
// }
// .ui-widget-icon-block {
//     left: 50%;
//     margin-left: -8px;
//     display: block;
// }
// .ui-widget-overlay {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
// }
// .ui-autocomplete {
//     position: absolute;
//     top: 0;
//     left: 0;
//     cursor: default;
// }
// .ui-menu {
//     list-style: none;
//     padding: 0;
//     margin: 0;
//     display: block;
//     outline: 0;
// }
// .ui-menu .ui-menu {
//     position: absolute;
// }
// .ui-menu .ui-menu-item {
//     margin: 0;
//     cursor: pointer;
//     list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
// }
// .ui-menu .ui-menu-item-wrapper {
//     position: relative;
//     padding: 3px 1em 3px 0.4em;
// }
// .ui-menu .ui-menu-divider {
//     margin: 5px 0;
//     height: 0;
//     font-size: 0;
//     line-height: 0;
//     border-width: 1px 0 0 0;
// }
// .ui-menu .ui-state-focus,
// .ui-menu .ui-state-active {
//     margin: -1px;
// }
// .ui-menu-icons {
//     position: relative;
// }
// .ui-menu-icons .ui-menu-item-wrapper {
//     padding-left: 2em;
// }
// .ui-menu .ui-icon {
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: 0.2em;
//     margin: auto 0;
// }
// .ui-menu .ui-menu-icon {
//     left: auto;
//     right: 0;
// }
// .ui-slider {
//     position: relative;
//     text-align: left;
// }
// .ui-slider .ui-slider-handle {
//     position: absolute;
//     z-index: 2;
//     cursor: ew-resize;
//     width: 16px;
//     height: 16px;
//     border-radius: 8px;
//     background: #FFF;
//     box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
//     -webkit-transition: box-shadow .2s;
//     transition: box-shadow .2s;
//     -ms-touch-action: none;
//     touch-action: none;
// }
// .ui-slider .ui-slider-handle:after {
//     top: 0;
//     display: block;
//     content: '';
//     position: absolute;
//     width: 6px;
//     height: 6px;
//     margin: 5px;
//     border-radius: 3px;
//     background: #005898;
//     -webkit-transition: -webkit-transform .2s;
//     transition: -webkit-transform .2s;
//     transition: transform .2s;
//     transition: transform .2s, -webkit-transform .2s;
// }
// .ui-slider .ui-slider-range {
//     position: absolute;
//     z-index: 1;
//     font-size: 0.7em;
//     display: block;
//     border: 0;
//     background-position: 0 0;
// }
// .ui-slider.ui-state-disabled .ui-slider-handle,
// .ui-slider.ui-state-disabled .ui-slider-range {
//     filter: inherit;
// }
// .ui-slider-horizontal {
//     height: 0.8em;
// }
// .ui-slider-horizontal .ui-slider-handle {
//     top: -5px;
//     margin-left: -7px;
// }
// .ui-slider-horizontal .ui-slider-range {
//     top: 0;
//     height: 100%;
// }
// .ui-slider-horizontal .ui-slider-range-min {
//     left: 0;
// }
// .ui-slider-horizontal .ui-slider-range-max {
//     right: 0;
// }
// .ui-slider-vertical {
//     width: 0.8em;
//     height: 100px;
// }
// .ui-slider-vertical .ui-slider-handle {
//     left: -0.3em;
//     margin-left: 0;
//     margin-bottom: -0.6em;
// }
// .ui-slider-vertical .ui-slider-range {
//     left: 0;
//     width: 100%;
// }
// .ui-slider-vertical .ui-slider-range-min {
//     bottom: 0;
// }
// .ui-slider-vertical .ui-slider-range-max {
//     top: 0;
// }
// .ui-widget {
//     font-family: Arial, Helvetica, sans-serif;
//     font-size: 1em;
// }
// .ui-widget .ui-widget {
//     font-size: 1em;
// }
// .ui-widget input,
// .ui-widget select,
// .ui-widget textarea,
// .ui-widget button {
//     font-family: Arial, Helvetica, sans-serif;
//     font-size: 1em;
// }
// // .ui-widget.ui-widget-content {
// //     border: 1px solid #c5c5c5;
// // }
// .ui-widget-content {
//     // border: 1px solid #ddd;
//     // background: #fff;
//     // color: #333;
//     background: #e0e0e0;
//     border-radius: 3px;
//     height: 6px;
// }
// .ui-widget-content a {
//     color: #333;
// }
// .ui-widget-header {
//     // border: 1px solid #ddd;
//     // background: #e9e9e9;
//     // color: #333;
//     // font-weight: bold;
//     border-radius: 2px;
//     background: #005898;
// }
// .ui-widget-header a {
//     color: #333;
// }
// // .ui-state-default,
// // .ui-widget-content .ui-state-default,
// // .ui-widget-header .ui-state-default,
// // .ui-button,
// // html .ui-button.ui-state-disabled:hover,
// // html .ui-button.ui-state-disabled:active {
// //     border: 1px solid #c5c5c5;
// //     background: #f6f6f6;
// //     font-weight: normal;
// //     color: #454545;
// // }

// .ui-state-default a,
// .ui-state-default a:link,
// .ui-state-default a:visited,
// a.ui-button,
// a:link.ui-button,
// a:visited.ui-button,
// .ui-button {
//     color: #454545;
//     text-decoration: none;
// }
// .ui-state-hover,
// .ui-widget-content .ui-state-hover,
// .ui-widget-header .ui-state-hover,
// .ui-state-focus,
// .ui-widget-content .ui-state-focus,
// .ui-widget-header .ui-state-focus,
// .ui-button:hover,
// .ui-button:focus {
//     border: none;
//     outline: none;
//     // background: #ededed;
//     // font-weight: normal;
//     // color: #2b2b2b;
// }
// .ui-state-hover a,
// .ui-state-hover a:hover,
// .ui-state-hover a:link,
// .ui-state-hover a:visited,
// .ui-state-focus a,
// .ui-state-focus a:hover,
// .ui-state-focus a:link,
// .ui-state-focus a:visited,
// a.ui-button:hover,
// a.ui-button:focus {
//     color: #2b2b2b;
//     text-decoration: none;
// }
// .ui-visual-focus {
//     box-shadow: 0 0 3px 1px rgb(94, 158, 214);
// }
// .ui-state-active,
// .ui-widget-content .ui-state-active,
// .ui-widget-header .ui-state-active,
// a.ui-button:active,
// .ui-button:active,
// .ui-button.ui-state-active:hover {
//     // border: 1px solid #337ab7;
//     // background: #337ab7;
//     // font-weight: normal;
//     // color: #fff;
// }
// .ui-icon-background,
// .ui-state-active .ui-icon-background {
//     border: #337ab7;
//     background-color: #fff;
// }
// .ui-state-active a,
// .ui-state-active a:link,
// .ui-state-active a:visited {
//     color: #fff;
//     text-decoration: none;
// }
// .ui-state-highlight,
// .ui-widget-content .ui-state-highlight,
// .ui-widget-header .ui-state-highlight {
//     border: 1px solid #dad55e;
//     background: #fffa90;
//     color: #777620;
// }
// .ui-state-checked {
//     border: 1px solid #dad55e;
//     background: #fffa90;
// }
// .ui-state-highlight a,
// .ui-widget-content .ui-state-highlight a,
// .ui-widget-header .ui-state-highlight a {
//     color: #777620;
// }
// .ui-state-error,
// .ui-widget-content .ui-state-error,
// .ui-widget-header .ui-state-error {
//     border: 1px solid #f1a899;
//     background: #fddfdf;
//     color: #5f3f3f;
// }
// .ui-state-error a,
// .ui-widget-content .ui-state-error a,
// .ui-widget-header .ui-state-error a {
//     color: #5f3f3f;
// }
// .ui-state-error-text,
// .ui-widget-content .ui-state-error-text,
// .ui-widget-header .ui-state-error-text {
//     color: #5f3f3f;
// }
// .ui-priority-primary,
// .ui-widget-content .ui-priority-primary,
// .ui-widget-header .ui-priority-primary {
//     font-weight: bold;
// }
// .ui-priority-secondary,
// .ui-widget-content .ui-priority-secondary,
// .ui-widget-header .ui-priority-secondary {
//     opacity: 0.7;
//     filter: Alpha(Opacity=70);
//     font-weight: normal;
// }
// .ui-state-disabled,
// .ui-widget-content .ui-state-disabled,
// .ui-widget-header .ui-state-disabled {
//     opacity: 0.35;
//     filter: Alpha(Opacity=35);
//     background-image: none;
// }
// .ui-state-disabled .ui-icon {
//     filter: Alpha(Opacity=35);
// }

// .ui-corner-all,
// .ui-corner-top,
// .ui-corner-left,
// .ui-corner-tl {
//     border-top-left-radius: 3px;
// }
// .ui-corner-all,
// .ui-corner-top,
// .ui-corner-right,
// .ui-corner-tr {
//     border-top-right-radius: 3px;
// }
// .ui-corner-all,
// .ui-corner-bottom,
// .ui-corner-left,
// .ui-corner-bl {
//     border-bottom-left-radius: 3px;
// }
// .ui-corner-all,
// .ui-corner-bottom,
// .ui-corner-right,
// .ui-corner-br {
//     border-bottom-right-radius: 3px;
// }
// .ui-widget-overlay {
//     background: #aaa;
//     opacity: 0.3;
//     filter: Alpha(Opacity=30);
// }
// .ui-widget-shadow {
//     -webkit-box-shadow: 0 0 5px #666;
//     box-shadow: 0 0 5px #666;
// }

/*
// .site-header
*/
@import '../variables';
@import '../functions';
@import '../mixins/direction';


.site-header {
    position: relative;
    z-index: 100;
    background: $header-bg;
    color: $header-font-color;

    &__callback {
        display: flex;
        flex-direction: column;
    }
}
.site-header__middle {
    display: flex;
    align-items: center;
    height: 104px;
}
.site-header__logo {
    flex-shrink: 0;
    // width: 255px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $header-logo-color;

    @include direction {
        #{$margin-inline-end}: 30px;
    }

    a {
        color: inherit;
    }

    svg {
        fill: currentColor;
    }

    &-image {
        max-height: 70px;
    }

    &--join {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        a {
            &:not(:first-child) {
                padding-left: .5rem;
                margin-left: .5rem;
                border-left: 1px solid #737373;
            }
        }

        span {
            font-size: .625rem;
            color: #737373;
            padding-top: .25rem;
        }
    }
}
.site-header__search {
    flex-grow: 1;
    margin-right: 2rem;
}
.site-header__phone {
    line-height: 1;
    flex-shrink: 0;

    &:not(:last-child) {
        margin-right: 2rem;
    }

    @include direction {
        text-align: $inline-end;
    }
}
.site-header__phone-title {
    font-size: 14px;
    color: $header-font-muted-color;
    margin-bottom: 6px;
}
.site-header__phone-number {
    font-size: 20px;
    font-weight: $font-weight-bold;
    color:#3d464d;

    &:hover {
        color: #3d464d;
    }
}
.site-header__nav-panel {
    height: $nav-panel-height;
}


@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
    .site-header__logo {
        // width: 210px;
        justify-content: flex-start;

        @include direction {
            #{$margin-inline-end}: 2rem;
        }
    }
}

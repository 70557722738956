.category-catalog  {

    display: flex;

    &__body {
        padding: 2rem 1rem 1rem;
        background: #fff;
        flex-grow: 1;
        display: flex;
        border-bottom: 2px solid $accent-color;
        transition: .2s;

        &:hover {
            box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
    
    &__spacer {
        flex-grow: 1;
    }

    &__name {
        font-size: 1.375rem;    
        margin-bottom: 4rem;
        color: $accent-color;
        text-align: center;
    }

    &__all {        
        text-align: center;
    }
}